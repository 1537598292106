import axios from "axios";
import React, { useEffect, useRef } from "react";
import { useContext } from "react";
import { useState } from "react";
import { checkAnswerApi } from "../../Api/Api.js";
import {
  CAMPANIE_CURENTA,
  POINTS_PER_CORRECT,
  TIME_PER_QUESTION,
} from "../../CONSTANTS.js";
import { UserContext } from "../../Providers/UserProvider.js";
import FinalScreen from "../FinalScreen/FinalScreen.jsx";
import Form from "../Form/Form.jsx";

const Questions = ({ questions }) => {
  const testRef = useRef(null);
  const scrollToElement = () => testRef.current.scrollIntoView();

  const [currentQuestion, setCurrentQuestion] = useState(-1);
  const [currentAnswer, setCurrentAnswer] = useState(null);
  const [points, setPoints] = useState(0);
  const [currentTime, setCurrentTime] = useState(TIME_PER_QUESTION);
  const [user] = useContext(UserContext);
  const [allAnswers, setAllAnswers] = useState([]);
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const [blocked, setBlocked] = useState(false);

  const [currentIsCorrect, setCurrentIsCorrect] = useState(null);
  const [currentCorrectAnswer, setCurrentCorrectAnswer] = useState(null);
  //
  const checkAnswer = () => {
    setBlocked(true);

    if (currentAnswer) {
      if (currentQuestion != -1) {
        addCurrentSelection();
      }

      checkAnswerApi(
        questions[currentQuestion].quiz,
        questions[currentQuestion].answer[currentAnswer]
      ).then((response) => {
        setCurrentIsCorrect(response.data.correct);
        setCurrentCorrectAnswer(response.data.answer);
        if (response.data.correct) {
          setPoints(points + POINTS_PER_CORRECT + currentTime);
          setCorrectAnswers(correctAnswers + 1);
        }
        setCurrentTime(0);
        setBlocked(false);
      });
    } else {
      setBlocked(false);
      return false;
    }
  };

  const renderCurrentCorrectAnswer = () => {
    return (
      <div className="max-w-md mx-auto">
        {currentIsCorrect == false ? (
          <div className="bg-[#FF0000] text-center text-white rounded-md my-2 p-2 sm:text-sm font-secondary">
            Răspunsul corect era: {currentCorrectAnswer}
          </div>
        ) : currentIsCorrect == null ? (
          <div className="bg-[#FF0000] text-center text-white rounded-md my-2 p-2 sm:text-sm font-secondary">
            Timpul a expirat!
          </div>
        ) : (
          <div className="bg-[#028A0F] text-center text-white rounded-md my-2 p-2 sm:text-sm font-secondary">
            Felicitări, ai răspuns corect!
          </div>
        )}
      </div>
    );
  };

  const addCurrentSelection = () => {
    const clone = allAnswers.slice(0);
    if (currentAnswer) {
      clone.push({
        quiz: questions[currentQuestion].quiz,
        answer: questions[currentQuestion].answer[currentAnswer],
        responseTime: currentTime,
      });
    } else {
      clone.push({
        quiz: questions[currentQuestion].quiz,
        answer: "",
        responseTime: currentTime,
      });
    }

    setAllAnswers(clone);
  };

  const handleNextQuestion = () => {
    setCurrentIsCorrect(null);
    setCurrentCorrectAnswer(null);
    setCurrentAnswer(null);
    setCurrentQuestion(currentQuestion + 1);
    setCurrentTime(TIME_PER_QUESTION);
    scrollToElement();

    // console.log(allAnswers);
  };

  const finish = () => {
    // Send to CMS
    setBlocked(true);
    axios
      .post(process.env.REACT_APP_REGISTER_ENDPOINT, {
        user: user,
        punctaj: points,
        campanie: CAMPANIE_CURENTA,
        raspunsuri_corecte: correctAnswers,
        allAnswers: allAnswers,
      })
      .then((response) => {
        // Finish
        setBlocked(false);
        handleNextQuestion();
        // console.log(response);
      });
  };

  useEffect(() => {
    if (currentTime == 0) {
      if (currentIsCorrect == null) {
        checkAnswer();
      }

      return;
    }
    const timer = setTimeout(() => setCurrentTime(currentTime - 1), 1000);
    return () => clearTimeout(timer);
  }, [currentTime]);

  return (
    <>
      <div ref={testRef}>
        {currentQuestion == -1 ? (
          <Form onClick={(e) => handleNextQuestion(e)} />
        ) : currentQuestion == questions.length ? (
          <FinalScreen points={points} correctAnswers={correctAnswers} />
        ) : (
          <div className="flex flex-col justify-center py-6 sm:py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full max-w-[1064px]"></div>

            <div className="mt-8 sm:mx-auto sm:w-full max-w-[1064px]">
              <div className="bg-white pb-8 pt-4 sm:pt-8 px-4 shadow sm:rounded-lg sm:px-10 w-full">
                {/* Timer */}
                <div className="p-2 sm:p-4 text-primary text-center font-secondary">
                  {currentTime} secunde
                </div>
                {/* Numar intrebare */}
                <div className="flex items-center justify-between text-sm">
                  <div className="flex items-center">
                    <h4 className="mt-10 font-secondary">
                      Punctaj: {points} puncte
                    </h4>
                  </div>
                  <div className="flex items-center">
                    <h4 className="mt-10 font-secondary">
                      Intrebarea {currentQuestion + 1} din {questions.length}
                    </h4>
                  </div>
                </div>
                {/* Intrbare */}
                <h2 className="my-2 font-bold text-lg sm:text-xl font-secondary">
                  {questions[currentQuestion].quiz}
                </h2>
                {/* Raspunsuri */}
                <div className="max-w-md mx-auto ">
                  {questions[currentQuestion].answer.map((item, index) => {
                    return (
                      <div key={index}>
                        <button
                          id={index}
                          value={index}
                          onClick={(e) => {
                            setCurrentAnswer(e.target.value);
                          }}
                          disabled={currentTime == 0}
                          className={`mb-2 font-secondary appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm ${
                            index == currentAnswer
                              ? "bg-primary text-white"
                              : ""
                          }`}
                        >
                          {item}
                        </button>
                      </div>
                    );
                  })}
                </div>

                {/* Correct Answer */}
                {currentTime == 0 ? renderCurrentCorrectAnswer() : ""}

                {/* Next */}
                {currentTime == 0 ? (
                  currentQuestion == questions.length - 1 ? (
                    <div className="flex justify-end max-w-md mx-auto">
                      <button
                        onClick={finish}
                        disabled={blocked}
                        className="inline-flex items-center self-end py-2 px-3 rounded-md sm:text-sm mt-4 bg-primary text-white"
                      >
                        {blocked ? (
                          <svg
                            className="w-5 h-5 mr-3 -ml-1 text-white animate-spin"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                        ) : (
                          ""
                        )}
                        Finalizează
                      </button>
                    </div>
                  ) : (
                    <div className="flex justify-end max-w-md mx-auto">
                      <button
                        onClick={handleNextQuestion}
                        disabled={blocked}
                        className="inline-flex items-center self-end py-2 px-3 rounded-md sm:text-sm mt-4 bg-primary text-white"
                      >
                        {blocked ? (
                          <svg
                            className="w-5 h-5 mr-3 -ml-1 text-white animate-spin"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                        ) : (
                          ""
                        )}
                        Întrebarea următoare
                      </button>
                    </div>
                  )
                ) : (
                  <div className="flex justify-end max-w-md mx-auto">
                    <button
                      onClick={checkAnswer}
                      disabled={blocked}
                      className="inline-flex items-center self-end py-2 px-3 rounded-md sm:text-sm mt-4 bg-primary text-white"
                    >
                      {blocked ? (
                        <svg
                          className="w-5 h-5 mr-3 -ml-1 text-white animate-spin"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      ) : (
                        ""
                      )}
                      Verifică răspuns
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Questions;
