/* eslint-disable react/prop-types */
import React, { createContext, useState } from "react";

export const UserContext = createContext();

export const UserProvider = (props) => {
  const [user, setUser] = useState({
    nume: "",
    prenume: "",
    email: "",
    telefon: "",
    username: "",
    tc: false,
    reguli: false,
  });

  return (
    <UserContext.Provider value={[user, setUser]}>
      {props.children}
    </UserContext.Provider>
  );
};
