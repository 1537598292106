import { React, useRef } from "react";
import { useContext } from "react";
import { UserContext } from "../../Providers/UserProvider";
import ReCAPTCHA from "react-google-recaptcha";
import { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ModalPhoneVerification from "../../Components/Modals/ModalPhoneVerification";
var qs = require("qs");

export default function Form({ onClick, ref }) {
  const [user, setUser] = useContext(UserContext);
  const [showModal, setShowModal] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const captchaRef = useRef(null);

  const onChange = (event) => {
    const { name, value } = event.target;
    setUser((prevState) => ({
      ...prevState, // shallow copy all previous state
      [name]: value, // update specific key/value
    }));
  };

  const sendSMSCode = async () => {
    var data = qs.stringify({
      phone: user.telefon,
    });
    var config = {
      method: "post",
      url: "https://cms.superbetdev.ro/wp-json/superbet/v1/generatecode",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };
    try {
      const res = await axios(config);
      return res;
    } catch (err) {
      return err.response;
    }
  };

  const handelSubmit = async (e) => {
    e.preventDefault();
    const token = captchaRef.current.getValue();
    captchaRef.current.reset();
    setIsDisabled(true);
    await axios
      .post(process.env.REACT_APP_RECAPTCHA_ENDPOINT, { token })
      .then((res) => {
        const currentResponse = JSON.parse(res.data.req.data);
        if (currentResponse.success) {
          sendSMSCode().then((res) => {
            if (res.status == 200) {
              setShowModal(true);
            } else if (res.status == 403) {
              if (res.data.message) {
                toast.error(res.data.message, {
                  position: "bottom-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              } else {
                toast.error("Eroare necunoscuta!", {
                  position: "bottom-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
            }
          });
        } else {
          toast.error("Eroare recaptcha", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        setIsDisabled(false);
      })
      .catch((error) => {
        setIsDisabled(false);
        console.log(error);
      });
  };
  return (
    <>
      {showModal ? (
        <ModalPhoneVerification
          number={user.telefon}
          setShowModal={setShowModal}
          onClickFunc={onClick}
        />
      ) : (
        ""
      )}
      <div
        className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8 font-primary"
        ref={ref}
      >
        <div className="sm:mx-auto sm:w-full max-w-[1064px]"></div>

        <div className="mt-8 sm:mx-auto sm:w-full  max-w-[1064px]">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6" onSubmit={handelSubmit}>
              <div>
                <label
                  htmlFor="nume"
                  className="block text-sm font-medium text-gray-700 "
                >
                  Nume
                </label>
                <div className="mt-1">
                  <input
                    onChange={onChange}
                    value={user.nume}
                    id="nume"
                    name="nume"
                    type="text"
                    autoComplete="nume"
                    placeholder="Nume"
                    required
                    className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none ${
                      user.nume
                        ? "focus:ring-green-600 focus:border-green-600"
                        : "focus:ring-primary focus:border-primary"
                    } sm:text-sm`}
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="prenume"
                  className="block text-sm font-medium text-gray-700"
                >
                  Prenume
                </label>
                <div className="mt-1">
                  <input
                    onChange={onChange}
                    value={user.prenume}
                    id="prenume"
                    name="prenume"
                    type="text"
                    autoComplete="prenume"
                    placeholder="Prenume"
                    required
                    className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none ${
                      user.prenume
                        ? "focus:ring-green-600 focus:border-green-600"
                        : "focus:ring-primary focus:border-primary"
                    } sm:text-sm`}
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email
                </label>
                <div className="mt-1">
                  <input
                    onChange={onChange}
                    value={user.email}
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    placeholder="Email"
                    required
                    className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none ${
                      user.email
                        ? "focus:ring-green-600 focus:border-green-600"
                        : "focus:ring-primary focus:border-primary"
                    } sm:text-sm`}
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="telefon"
                  className="block text-sm font-medium text-gray-700"
                >
                  Telefon
                </label>
                <div className="mt-1">
                  <input
                    onChange={onChange}
                    value={user.telefon}
                    id="telefon"
                    name="telefon"
                    type="tel"
                    pattern="[0-9]{10}"
                    autoComplete="telefon"
                    placeholder="Ex: 0744433322"
                    required
                    className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none ${
                      user.telefon
                        ? "focus:ring-green-600 focus:border-green-600"
                        : "focus:ring-primary focus:border-primary"
                    } sm:text-sm`}
                  />
                </div>
              </div>

              <div>
                <div className="flex ">
                  <div>
                    <input
                      onChange={onChange}
                      value={user.tc}
                      id="tc"
                      name="tc"
                      type="checkbox"
                      className="accent-primary h-4 w-4 block text-primary focus:ring-primary border-gray-300 rounded"
                    />
                  </div>
                  <label
                    htmlFor="tc"
                    className="ml-2 block text-xs text-gray-900 font-secondary"
                  >
                    Declar pe propria răspundere că am 18 ani împliniți și am
                    citit, am înțeles, sunt de acord cu Termenii și condițiile
                    Superbet, și am citit, am înțeles Declarația de
                    Confidențialitate Superbet
                  </label>
                </div>

                <div className="flex mt-2">
                  <div>
                    <input
                      id="reguli"
                      name="reguli"
                      type="checkbox"
                      onChange={onChange}
                      value={user.reguli}
                      className="accent-primary h-4 w-4 text-primary focus:ring-primary border-gray-300 rounded"
                    />
                  </div>
                  <label
                    htmlFor="reguli"
                    className="ml-2 block text-xs text-gray-900 font-secondary"
                  >
                    Sunt de acord cu utilizarea datelor mele personale de către
                    Superbet, prin mijloacele de comunicare puse la dispoziție,
                    pentru a primi noutăți despre bonusuri, oferte, jocuri
                    gratuite, cote. Vezi Politica de Confidențialitate
                  </label>
                </div>
              </div>
              <div>
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_SITE_KEY}
                  ref={captchaRef}
                />
              </div>
              <div>
                <button
                  type="submit"
                  disabled={isDisabled}
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-[#b92317] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary font-secondary"
                >
                  Începe quiz-ul
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
