import React, { useEffect } from "react";
import axios from "axios";
import Questions from "../Questions/Questions";
import { useState } from "react";

const Quiz = () => {
  const [questions, setQuestions] = useState([]);

  const fetchData = () => {
    axios
      .get(process.env.REACT_APP_BACKEND_ENDPOINT + "/questions?campanie=19")
      .then((response) => {
        // Finish
        setQuestions(response.data.questions);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {questions.length > 0 ? (
        <Questions questions={questions} />
      ) : (
        <div className="my-4 flex items-center justify-center ">
          <div className="w-20 h-20 border-t-4 border-b-4 border-primary rounded-full animate-spin"></div>
        </div>
      )}
    </>
  );
};

export default Quiz;
