import React, { useEffect } from "react";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import Hero from "../../Components/Hero/Hero";
import Quiz from "../Quiz/Quiz";
import { Helmet } from "react-helmet";

export default function Acasa() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>SuperBet</title>
        <meta
          name="description"
          content="Câștigă SuperTricoul echipei tale preferate din Anglia!."
        />
        <meta
          name="keywords"
          content="superbet, pariuri sportive, super bet, bonus, SUPERPREMII, SUPER, PREMII"
        ></meta>
        <meta property="og:locale" content="en_US"></meta>
        <meta property="og:type" content="article"></meta>
        <meta
          property="og:title"
          content="Câștigă SuperTricoul echipei tale preferate din Anglia!."
        ></meta>
        <meta
          property="og:description"
          content="Și zeci de alte premii la fel de super."
        ></meta>
        {/* TODO */}
        {/* <meta property="og:image" content="https://v.fastcdn.co/u/744530a1/62248996-0-bmw.desk.JPG"></meta> */}
        <html className="scroll-smooth" />
      </Helmet>
      <Header />
      <Hero />
      <div id="quiz">
        <Quiz />
      </div>
      <Footer />
    </>
  );
}
