import { Link } from "react-router-dom";
const Button = ({ title, link, style }) => {
  return (
    <>
      <div className="w-full md:w-auto">
        <a
          className={`block text-center font-primary font-bold uppercase py-3 rounded text-xs md:text-base lg:text-lg px-5 md:px-10 lg:px-16 whitespace-nowrap w-full md:w-auto ${
            style == "secondary"
              ? "bg-secondary text-white hover:text-white hover:bg-hover"
              : ""
          }
        ${
          style == "white"
            ? "bg-white text-secondary hover:text-white hover:bg-hover"
            : ""
        }
        `}
          href={link}
        >
          {title}
        </a>
      </div>
    </>
  );
};
export default Button;
