import phone from '../../Images/phones-min.png';
import googleplay from '../../Images/Google-Play.svg';
import appstore from '../../Images/AppStore.svg';
import huaweiapp from '../../Images/huawei-appgalleryz.svg';
import email from '../../Images/email-min.svg';
import phonesvg from '../../Images/telephone-min.svg';
import impreuna from '../../Images/Logo-SB-impreuna.svg';

import twitter from '../../Images/twitter.svg';
import facebook from '../../Images/facebook.svg';
import instagram from '../../Images/instagram.svg';
import youtube from '../../Images/youtube.svg';
import onjn from '../../Images/ONJN.png';
import svg_18 from '../../Images/18.svg';
import rombet from '../../Images/rombet-logo.svg';
import international from '../../Images/International-Bettin.svg';
import responsabil from '../../Images/Joc-Responsabil.svg';

const Footer = () => {
  return (
    <>
      <footer>
        <div className="bg-secondary pb-10 sm:pb-0">
          <div className="container-custom font-primary">
            <div className="flex flex-col md:grid grid-cols-2 gap-5">
              <div className="col-span-1">
                <img
                  src={phone}
                  className="w-full lg:w-3/4 -mt-[20px] sm:mt-0 sm:-mb-[60px]"
                />
              </div>
              <div className="col-span-1 flex flex-col text-white">
                <h3 className="text-center sm:text-left text-3xl md:text-4xl font-bold pt-5 sm:pt-10 pb-5 font-secondary">
                  Descarcă Aplicațiile
                </h3>
                <p className="text-center sm:text-left text-sm sm:text-base pb-5 sm:pb-10 font-secondary">
                  Shining Crown, Burning Hot, 40 Super Hot, 5 Dazzling Hot, 20
                  Super Hot, jocuri casino online, Jackpot, rotiri gratuite,
                  promoții, premii, casino live cu mese exclusive de blackjack
                  live și ruletă, plus free spins fără depunere.
                </p>
                <div className="flex justify-center gap-5 flex-row sm:flex-col">
                  <div>
                    <h3 className="text-2xl sm:text-3xl font-medium pb-3 sm:pb-5 font-secondary">
                      Sport App
                    </h3>
                    <div className="flex flex-col items-center sm:flex-row gap-5 lg:gap-10 pb-5">
                      <div>
                        <a
                          href="https://play.google.com/store/apps/details?id=ro.superbet.sport&gl=RO"
                          target="_blank"
                        >
                          <img src={googleplay} className="h-[37px]" />
                        </a>
                      </div>
                      <div className="">
                        <a
                          href="https://apps.apple.com/ro/app/superbet-sport/id1314878525"
                          target="_blank"
                        >
                          <img src={appstore} className="h-[37px]" />
                        </a>
                      </div>
                      <div>
                        <a
                          href="https://appgallery.huawei.com/#/app/C102997357"
                          target="_blank"
                        >
                          <img src={huaweiapp} className="h-[37px]" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div>
                    <h3 className="text-2xl sm:text-3xl font-medium pb-3 sm:pb-5 font-secondary">
                      Games App
                    </h3>
                    <div className="flex flex-col items-center sm:flex-row gap-5 lg:gap-10 pb-5">
                      <div>
                        <a
                          href="https://play.google.com/store/apps/details?id=ro.superbet.games"
                          target="_blank"
                        >
                          <img src={googleplay} className="h-[37px]" />
                        </a>
                      </div>
                      <div className="">
                        <a
                          href="https://apps.apple.com/ro/app/superbet-games/id1447417739"
                          target="_blank"
                        >
                          <img src={appstore} className="h-[37px]" />
                        </a>
                      </div>
                      <div>
                        <a
                          href="https://appgallery.huawei.com/#/app/C103988579"
                          target="_blank"
                        >
                          <img src={huaweiapp} className="h-[37px]" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-[#313131] text-white font-primary">
          <div className="container-custom pt-8 sm:pt-16">
            <div className="flex flex-col sm:flex-row gap-5 sm:gap-10 pb-10">
              <div className="text-lg font-medium font-roboto">
                <h4 className="pb-5">Resurse</h4>
                <div className="flex flex-col whitespace-nowrap text-gray-300">
                  <a
                    target="_blank"
                    href="https://superbet.ro/wiki/termeni-si-conditii?_gl=1%2a7x8gob%2a_ga%2aMTEzNjMwMzYyMS4xNjYwMTIyMTkz%2a_ga_5NP25TYCZ5%2aMTY2MDEzMzExNS4zLjEuMTY2MDEzNTA1NC42MA.."
                    className="hover:underline"
                  >
                    Termene și Condiții
                  </a>
                  <a
                    target="_blank"
                    href="https://superbet.ro/wiki/regulamente-de-joc?_gl=1%2a1muglyh%2a_ga%2aMTEzNjMwMzYyMS4xNjYwMTIyMTkz%2a_ga_5NP25TYCZ5%2aMTY2MDEzMzExNS4zLjEuMTY2MDEzNTA1Ny41Nw.."
                    className="hover:underline"
                  >
                    Regulamente de Joc
                  </a>
                  <a
                    target="_blank"
                    href="https://superbet.ro/wiki/joc-responsabil?_gl=1%2a1pxrekd%2a_ga%2aMTEzNjMwMzYyMS4xNjYwMTIyMTkz%2a_ga_5NP25TYCZ5%2aMTY2MDEzMzExNS4zLjEuMTY2MDEzNTA5NC4yMA.."
                    className="hover:underline"
                  >
                    Joc Responsabil
                  </a>
                  <a
                    target="_blank"
                    href="https://superbet.ro/wiki/metode-de-plata-online?_gl=1%2a9vsvbo%2a_ga%2aMTEzNjMwMzYyMS4xNjYwMTIyMTkz%2a_ga_5NP25TYCZ5%2aMTY2MDEzMzExNS4zLjEuMTY2MDEzNTA5Ni4xOA.."
                    className="hover:underline"
                  >
                    Metode de Plată
                  </a>
                  <a
                    target="_blank"
                    href="https://superbet.ro/wiki/politica-impotriva-spalarii-banilor?_gl=1%2a1wcyy16%2a_ga%2aMTEzNjMwMzYyMS4xNjYwMTIyMTkz%2a_ga_5NP25TYCZ5%2aMTY2MDEzMzExNS4zLjEuMTY2MDEzNTExMC40"
                    className="hover:underline"
                  >
                    Politica AML
                  </a>
                </div>
              </div>
              <div className="w-full font-roboto">
                <h3 className="pb-5 text-lg font-medium">Contactează-ne</h3>
                <div className="pb-5">
                  <a
                    href="mailto:suport@superbet.ro"
                    className="flex gap-5 font-medium"
                  >
                    <div className="w-[28px]">
                      <img src={email} />
                    </div>
                    <span>suport@superbet.ro</span>
                  </a>
                </div>
                <div className="font-medium">
                  <a href="https://wa.me/0725154154" className="flex gap-5">
                    <div className="w-[28px]">
                      <img src={phonesvg} className="w-[20px] mx-auto" />
                    </div>
                    <span>0725 154 154</span>
                  </a>
                </div>
                <div className="">
                  <div className="flex gap-12">
                    <div className=""></div>
                    <span className="text-xs text-gray-300 font-medium">
                      Important: numărul de WhatsApp este exclusiv pentru chat.
                      Echipa de suport îți va răspunde în scris. Te rugăm să nu
                      efectuezi apeluri la acest număr.
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-span-2 text-xs font-secondary font-normal text-gray-300">
                <p className="pb-5">
                  Accesul persoanelor sub 18 ani este strict interzis.
                </p>
                <p>
                  Este responsabilitatea fiecărui jucător să acționeze în
                  conformitate cu reglementările în vigoare precum și a
                  termenilor și condițiilor noastre.
                </p>
                <p className="pb-5">
                  Jocurile de noroc implică risc financiar, jucați cu grijă.
                </p>
                <p>
                  Superbet.ro este deținut și operat de Superbet Interactive
                  (Romania) Limited, C.I.F.: 36446625, Număr de Înregistrare
                  C75273; Level G, (Office 1/5597), Quantum House, 75 Abate
                  Rigord Street, Ta’Xbiex XBX 1120, Malta. Drept de organizare
                  în baza licenței L1160659W000328 cu valabilitate
                  01.09.2016-31.08.2026, obținută prin decizia 1553 din
                  30.06.2022
                </p>
                <p>Licența ONJN: L1160659W000328</p>
                <p>Licența ONJN: RO3229L001332</p>{' '}
              </div>
            </div>
            <div className="pb-10">
              <div className="flex flex-col lg:flex-row gap-6 sm:gap-12 items-center justify-between">
                <div>
                  <img src={impreuna} className="w-full max-w-[150px]" />
                </div>
                <div className="flex gap-5 items-center">
                  <a href="" className="block w-[26px] h-[26px]">
                    <img src={twitter} />
                  </a>
                  <a href="" className="block w-[26px] h-[26px]">
                    <img src={facebook} />
                  </a>
                  <a href="" className="block w-[26px] h-[26px]">
                    <img src={instagram} />
                  </a>
                  <a href="" className="block w-[26px] h-[26px]">
                    <img src={youtube} />
                  </a>
                </div>
                <div className="flex flex-col sm:flex-row gap-5 items-center ">
                  <div className="flex flex-row gap-2 sm:gap-5">
                    <a href="http://onjn.gov.ro/" target="_blank">
                      <img src={onjn} className="w-[26px] h-[26px]" />
                    </a>
                    <div>
                      <img src={svg_18} className="w-[26px] h-[26px]" />
                    </div>
                  </div>
                  <div className="flex gap-2 sm:gap-5">
                    <div>
                      <img src={rombet} className="h-[26px]" />
                    </div>
                    <div>
                      <img src={international} className="h-[26px]" />
                    </div>
                    <a
                      href="https://superbet.ro/wiki/joc-responsabil?_gl=1%2a1xnj9le%2a_ga%2aMTEzNjMwMzYyMS4xNjYwMTIyMTkz%2a_ga_5NP25TYCZ5%2aMTY2MDEzMzExNS4zLjEuMTY2MDEzNTE0OC42MA.."
                      target="_blank"
                    >
                      <img src={responsabil} className="h-[30px]" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
