import logo from '../../Images/logo.webp';
import Button from '../Button/Button';

const Header = () => {
  return (
    <div className="bg-primary font-primary bg-no-repeat bg-right-center bg-contain overflow-x-hidden">
      <div className="container-custom py-5">
        <div className="flex flex-col-reverse md:flex-row justify-between items-center gap-5 sm:gap-8 ">
          <div className="w-full flex justify-center md:justify-start">
            <img src={logo} />
          </div>
          {/* <div className="flex gap-5 sm:gap-8 w-full justify-center md:justify-end">
            <Button
              title="Înregistrare"
              link="https://www.superbet.ro/inregistrare"
              style="secondary"
            />
            <Button
              title="Intră în cont"
              link="https://club.superbet.ro/#/home"
              style="white"
            />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Header;
