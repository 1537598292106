import axios from "axios";
function b64EncodeUnicode(str) {
  // first we use encodeURIComponent to get percent-encoded UTF-8,
  // then we convert the percent encodings into raw bytes which
  // can be fed into btoa.
  return btoa(
    encodeURIComponent(str).replace(
      /%([0-9A-F]{2})/g,
      function toSolidBytes(match, p1) {
        return String.fromCharCode("0x" + p1);
      }
    )
  );
}

export const checkAnswerApi = async (question, answer) => {
  //   console.log(b64EncodeUnicode(question) + " " + question);
  try {
    const res = await axios.get(
      process.env.REACT_APP_BACKEND_ENDPOINT +
        "/checkanswer?campanie=19&question=" +
        b64EncodeUnicode(question) +
        "&answer=" +
        b64EncodeUnicode(answer)
    );
    const response = res.data;
    return res;
  } catch (err) {
    console.log(err);
    return err.response;
  }
};
