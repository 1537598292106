import React from 'react';

export default function FinalScreen({ points, correctAnswers }) {
  return (
    <>
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full max-w-[1064px]"></div>

        <div className="mt-8 sm:mx-auto sm:w-full max-w-[1064px]">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <div className="text-center">
              <h2 className="mt-8 mb-4 font-bold text-xl text-center font-secondary">
                Îți mulțumim pentru participare!
              </h2>
              <h3 className="text-l my-4 font-secondary">
                Ai obținut{' '}
                <span className="text-primary font-bold">{points} </span>
                puncte din{' '}
                <span className="text-primary font-bold">
                  {correctAnswers}
                </span>{' '}
                răspunsuri corecte.
              </h3>
              <p className="pb-5 font-secondary">
                Dacă ai răspuns corect la cel puțin 8 din întrebările din Quiz,
                vei intra în tragerea la sorți săptămânală pentru SuperTricoul
                unei echipe din Anglia!
              </p>
              <iframe
                width="100%"
                className="rounded-md h-[200px] sm:h-[400px] lg:h-[500px]"
                src="https://www.youtube.com/embed/EYx8LfHQ3XY"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
              <a
                href="https://superbet.ro"
                className="font-secondary mt-4 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
              >
                Spre website
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
