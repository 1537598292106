import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { UserContext } from "../../Providers/UserProvider";
var axios = require("axios");
var qs = require("qs");

const ModalPhoneVerification = (props) => {
  const [code, setCode] = useState("");
  const [nrSecunde, setNrSecunde] = useState(-1);
  const [user, setUser] = useContext(UserContext);
  var interval;
  useEffect(() => {
    interval = setInterval(() => {
      setNrSecunde((nrSecunde) => nrSecunde - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const onChange = (event) => {
    const { name, value } = event.target;
    setCode(value);
  };
  const closeModal = (e) => {
    e.preventDefault();
    props.setShowModal(false);
  };
  const handleSubmitCode = () => {
    var data = qs.stringify({
      phone: props.number,
      code: code,
    });
    var config = {
      method: "post",
      url: "https://cms.superbetdev.ro/wp-json/superbet/v1/verifycode",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        if (response.data.success == true) {
          props.onClickFunc();
          setUser((prevState) => ({
            ...prevState,
            ["code"]: code,
          }));
        } else {
          toast.error("eroare", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch(function (error) {
        toast.error(error.response.data.message, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const handleResendCode = () => {
    if (nrSecunde > 0) {
      toast.error("Nu puteti retrimite inca un cod atat de repede!", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    setNrSecunde(60);
    var data = qs.stringify({
      phone: props.number,
      code: code,
    });
    var config = {
      method: "post",
      url: "https://cms.superbetdev.ro/wp-json/superbet/v1/resendcode",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.status == 200) {
          toast.success("Codul a fost retrimis cu succes!", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error("Eroare necunoscuta!", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch(function (error) {
        toast.error(error.response.data.message, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  return (
    <>
      <div
        class="relative z-10"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
              <div>
                <div class="mt-3 text-center sm:mt-5">
                  <h3
                    class="text-lg font-medium leading-6 text-gray-900"
                    id="modal-title"
                  >
                    Am trimis un SMS pe numarul {props.number}
                  </h3>
                  <div class="mt-2">
                    <p class="text-sm text-gray-500">
                      Veti primi un cod OTP prin SMS pe numarul de telefon
                      afisat. Te rugam sa introduci codul primit mai jos pentru
                      verificare.
                    </p>
                  </div>
                  <div>
                    <div className="mt-1">
                      <input
                        onChange={onChange}
                        value={code}
                        id="prenume"
                        name="prenume"
                        type="text"
                        autoComplete="prenume"
                        placeholder=""
                        required
                        className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none ${
                          true
                            ? "focus:ring-green-600 focus:border-green-600"
                            : "focus:ring-primary focus:border-primary"
                        } sm:text-sm`}
                      />
                    </div>
                  </div>
                  <div class="mt-2">
                    <p class="text-xs text-gray-500">
                      <span
                        onClick={handleResendCode}
                        className="cursor-pointer"
                      >
                        Nu ai primit codul? Retrimite
                      </span>
                    </p>
                  </div>
                  {nrSecunde <= 0 ? (
                    ""
                  ) : (
                    <div class="mt-2">
                      <p class="text-xs text-gray-500">
                        <span>
                          Mai sunt {nrSecunde} secunde pana cand poti retrimite
                          un nou cod
                        </span>
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div class="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                <button
                  onClick={handleSubmitCode}
                  type="button"
                  class="bg-primary hover:bg-[#b92317] inline-flex w-full justify-center rounded-md border border-transparent  px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                >
                  Trimite
                </button>
                <button
                  type="button"
                  onClick={closeModal}
                  class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                >
                  Anuleaza
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalPhoneVerification;
