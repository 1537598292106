import tricouri from "../../Images/tricouri.png";
import etapa from "../../Images/etapa-2.webp";
import Button from "../Button/Button";

const Hero = () => {
  return (
    <>
      <div className="bg-primary overflow-hidden py-5 lg:py-10">
        <div className="container-custom ">
          <div className="flex flex-col-reverse md:grid md:grid-cols-3 items-center relative ">
            <div className="flex flex-col md:col-span-3 relative z-10">
              <div className="pt-5 md:pt-10 text-white">
                <h1 className="text-center md:text-left text-2xl sm:text-3xl lg:text-5xl font-primary font-black pb-10 sm:pb-20 lg:pb-32">
                  Câștigă SuperTricoul echipei <br />
                  tale preferate din Anglia!
                </h1>
                {/* <h3 className="text-center md:text-left pb-5 text-xl sm:text-2xl font-normal">
                  Și zeci de alte super premii
                </h3> */}
                <div className="flex justify-start ">
                  <Button title="Joaca Acum" link="#quiz" style="secondary" />
                </div>
                <div className="hidden sm:flex gap-5 items-center justify-center sm:justify-start py-5 sm:py-10">
                  <div>{/* <img src={etapa} /> */}</div>
                  {/* <p className=" font-primary text-sm">
                    Perioada de înscriere <br /> 1 August - 5 Octombrie 2022
                  </p> */}
                </div>
              </div>
            </div>
            <div>
              <img
                src={tricouri}
                className="w-full md:w-2/3 z-0 md:absolute top-0 lg:-top-[60px] -right-[200px]"
              />
            </div>
          </div>
          <div className="grid lg:grid-cols-3 gap-3 text-secondary relative z-10 pt-10 sm:pt-0">
            <div className="bg-white rounded-2xl py-8 px-5 flex items-center">
              <div className="flex items-center gap-2">
                <h2 className="text-5xl sm:text-6xl font-bold">1</h2>
                <div className="flex flex-col">
                  {/* <h4 className="text-lg sm:text-xl xl:text-2xl font-bold">
                    PARIEZI
                  </h4> */}
                  <h5 className="text-base sm:text-lg xl:text-2xl font-normal">
                    Înscrie-te în campanie
                  </h5>
                </div>
              </div>
            </div>
            <div className="bg-white rounded-2xl py-8 px-5 flex items-center">
              <div className="flex items-center gap-2">
                <h2 className="text-5xl sm:text-6xl font-bold">2</h2>
                <div className="flex flex-col">
                  {/* <h4 className="text-lg sm:text-xl xl:text-2xl font-bold">
                    SCANEZI
                  </h4> */}
                  <h5 className="text-base sm:text-lg xl:text-2xl font-normal ">
                    Răspunde corect întrebărilor din quiz
                  </h5>
                </div>
              </div>
            </div>
            <div className="bg-white rounded-2xl py-8 px-5 flex items-center">
              <div className="flex items-center gap-2">
                <h2 className="text-5xl sm:text-6xl font-bold">3</h2>
                <div className="flex flex-col">
                  {/* <h4 className="text-lg sm:text-xl xl:text-2xl font-bold">
                    CÂȘTIGI
                  </h4> */}
                  <h5 className="text-base sm:text-lg xl:text-2xl font-normal">
                    Urmărește tragerea la sorți săptămânală
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center py-5 relative z-10 sm:mt-20">
            <a
              href="https://superbet.ro/wiki/regulament-superquiz-011022"
              target="_blank"
              className="text-white font-semibold"
            >
              REGULAMENT
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
